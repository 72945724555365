import React, { useEffect } from "react";

import { Backdrop, Typography, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

const Proceeding = ({ open, setOpen }) => {
  const isProceeding = useSelector(
    (state) => state.app.Websocket_data.isPaymentProceeding
  );
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isProceeding}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CircularProgress color="inherit" />
      <Typography variant="h5">Payment Under Process</Typography>
    </Backdrop>
  );
};

export default Proceeding;
