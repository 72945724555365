import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
const wrap = {
  "min-height": "100vh",
};
const alignCenter = {
  height: "100vh",
  display: "flex",
  "justify-content": "center",
};
let isTrue = false;
const subdomain = window.location.host.split(".")[0];
let arr = ["www", "insmeal", "develop", "stage", "prod", "localhost:3000"];
if (!arr.includes(subdomain)) {
  isTrue = true;
}
const ErrorPage = () => {
  return (
    <div style={wrap}>
      <Header />
      <div
        class="page-wrap d-flex flex-row align-items-center"
        style={alignCenter}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <span class="display-1 d-block">404</span>
              <div class="mb-4 lead">
                The page you are looking for was not found.
              </div>
              <Link to={!isTrue ? "/" : "/Home"} class="btn btn-link">
                Back to Insmeal
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
