import React, { useContext, useEffect, Suspense, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import { Auth } from "aws-amplify";
import { AuthContext } from "./context/AuthContext";
import AuthRoute from "./AuthRoute";
import ScrollToTop from "./components/ScrollToTop";
import Loading from "./components/Loading";
import ErrorPage from "./components/ErrorPage";
import { ErrorBoundary } from "@sentry/react";
import FallbackComp from "./components/FallBackComp";

import Proceeding from "./components/Proccessing";
import OrderModal from "./components/OrderModal";

import lazyRetry from "./lazyRetry";

function AppRoutes() {
  const { login } = useContext(AuthContext);

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        if (data) {
          let idToken = data.getIdToken().getJwtToken();
          login(idToken);
        }
      })
      .catch((err) => console.log("no user"));
  }, []);

  const Home = React.lazy(() => lazyRetry(() => import("./pages/Home")));
  const DiscoverRestaurants = React.lazy(() =>
    lazyRetry(() => import("./pages/DiscoverRestaurants"))
  );
  const RestaurantLanding = React.lazy(() =>
    lazyRetry(() => import("./pages/RestaurantLanding"))
  );
  const Order = React.lazy(() =>
    lazyRetry(() => import("./pages/OrderStatus/index"))
  );
  const UserProfile = React.lazy(() =>
    lazyRetry(() => import("./pages/UserProfile/index"))
  );
  const TableCheckout = React.lazy(() =>
    lazyRetry(() => import("./pages/TableCheckout/TableCheckout"))
  );
  const Menu = React.lazy(() => lazyRetry(() => import("./pages/Menu")));
  const Checkout = React.lazy(() =>
    lazyRetry(() => import("./pages/Checkout/Index"))
  );

  let isTrue = false;
  const subdomain = window.location.host.split(".")[0];
  let arr = [
    "www",
    "insmeal",
    "develop",
    "stage",
    "prod",
    "localhost:3000",
    "localhost:3006",
  ];
  if (!arr.includes(subdomain)) {
    isTrue = true;
  }

  const [errorMessage, setErrorMessage] = useState("");
  const onError = (error) => {
    console.log("we got eerror", error);

    setErrorMessage(error.message);
  };
  return (
    <ErrorBoundary
      fallback={<FallbackComp error={errorMessage} />}
      onError={onError}
    >
      <OrderModal />
      <Proceeding />

      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <ScrollToTop />
          <Switch>
            <Route path={!isTrue ? "/" : "/Home"} exact>
              {<Home />}
            </Route>

            {/* <Route path={"/wallet"} component={Wallet} /> */}

            <Route exact path="/not-found" component={ErrorPage} />
            <Route
              path="/discover-restaurants"
              component={DiscoverRestaurants}
            />
            <Route
              path="/restaurant-landing-page"
              component={RestaurantLanding}
            />
            <Route path="/pay/:amount" component={TableCheckout} />
            <ProtectedRoutes path="/checkout" component={Checkout} />
            <AuthRoute path="/my-profile" component={UserProfile} />
            <Route path="/:restName/order-status/:orderId" component={Order} />
            <Route
              path="/:restName/:status/order-status/:orderId"
              component={Order}
            />
            <ProtectedRoutes path="/:restName/checkout" component={Checkout} />
            <Route path="/:restName/menu" component={Menu} />
            <Route path={isTrue ? "/" : "/:restName"}>
              <RestaurantLanding />
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default AppRoutes;
