import { createContext, useState, useContext, useMemo } from "react";
import { ThemeContext } from "./context";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
const constant = {
  CART_IDS: "cart_ids",
};

const getAlreadyExistedCartids = () => {
  if (Cookies.get(constant.CART_IDS)) {
    return JSON.parse(Cookies.get(constant.CART_IDS));
  } else return {};
};
let initialState = {
  cartIds: getAlreadyExistedCartids(),
};

const RestaurantContext = createContext({
  ...initialState,
  getRestCartId: () => {},
  addRestCartId: () => {},
  removeRestCartId: () => {},
});
const RestaurantProvider = (props) => {
  const search = window.location.search;
  const state = useSelector((state) => state.app.Websocket_data);
  const [cartIds, setCartIds] = useState({ ...initialState.cartIds });
  const { theme } = useContext(ThemeContext);
  const restId = useMemo(() => theme?.data?.id || undefined, [theme]);
  const getRestCartId = () => {
    const table_number = new URLSearchParams(search).get("table_number");
    console.log(table_number);
    if (table_number) {
      return state?.connected?.cartid;
    } else
      return (
        (cartIds.hasOwnProperty(restId) &&
          cartIds[restId].length > 0 &&
          cartIds[restId]) ||
        undefined
      );
  };
  const addRestCartId = (cartId) => {
    console.log("inside the setcart", state?.connected?.connected);

    if (!state?.connected?.connected) {
      Cookies.set(
        constant.CART_IDS,
        JSON.stringify({ ...cartIds, [restId]: cartId })
      );
      setCartIds((prev) => ({
        ...prev,
        [restId]: cartId,
      }));
    }
  };
  const removeRestCartId = () => {
    let cartIdsClone = cartIds;
    delete cartIdsClone[restId];
    Cookies.set(constant.CART_IDS, JSON.stringify({ ...cartIdsClone }));
    setCartIds((prev) => ({
      ...cartIdsClone,
    }));
  };

  return (
    <RestaurantContext.Provider
      value={{ cartIds, getRestCartId, addRestCartId, removeRestCartId }}
      {...props}
    />
  );
};

export { RestaurantContext, RestaurantProvider };
