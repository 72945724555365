import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, ToggleButtonGroup, Typography } from "@material-ui/core";
import styles from "./styles.module.css";
import Modal from "react-modal";
import { Auth } from "aws-amplify";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/core/Alert";
import { CartContext } from "../context/context";
import { AuthContext } from "../context/AuthContext";
import userIcon from "../assets/user-icon.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import { useMediaQuery } from 'react-responsive';

const ModalComp = (props) => {
  // const { CartItem } = useContext(CartContext);
  // const { restName } = useParams();

  const { login, user } = useContext(AuthContext);
  const [isOpenAuth, setIsOpenAuth] = useState(false);
  const [IsAuth, setIsAuth] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);

  const [timeStamp, setTimeStamp] = useState();

  const history = useHistory();

  const [restTime, setRestTime] = useState();
  const [disabledd, setDisabledd] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ResendOtp, setResendOtp] = useState(true);
  const [RemainderTime, setRemainderTime] = useState(0);
  function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let hh = h > 12 ? h - 12 : h;
    let m = Math.floor((d % 3600) / 60);
    if (m === 0) {
      m = "00";
    }
    let ampm = h >= 12 ? "PM" : "AM";
    return hh + ":" + m + " " + ampm;
  }

  useEffect(() => {
    Auth.currentUserInfo()
      .then((user) => {
        if (user.attributes?.phone_number) {
          setIsAuth(true);
        } else {
          setIsAuth(false);
        }
      })
      .catch((err) => console.log(err));
    const interval =
      RemainderTime > 0 &&
      setInterval(() => setRemainderTime(RemainderTime - 1), 1000);
    return () => clearInterval(interval);
  }, [RemainderTime]);

  const handleResendotp = () => {
    setTimeout(() => {
      setResendOtp(false);
    }, 30000);
    setResendOtp(true);
  };
  const handleCheck = () => {
    setLoading(false);
    setDisabledd((prev) => !prev);
    dateTime
      ? isOpenAuth || IsAuth
        ? openOtp || IsAuth
          ? history.push(`/my-profile`)
          : toggleModalOtp()
        : toggleAuthModal()
      : toggleModal();
  };

  const [alignment, setAlignment] = useState("Pick Up");
  const [isOpen, setIsOpen] = useState(false);
  const [dateTime, setDateTime] = useState(true);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleDateTime = () => {
    setDateTime(true);
    toggleModal();
    let myVar = new Date(restTime * 1000);
    myVar.setUTCHours(0, 0, 0, 0);
    let myTime = Date.parse(myVar);
    localStorage.setItem("timeStamp", myTime + time * 1000);
    setTimeStamp(myTime + time * 1000);
  };

  function toggleAuthModal() {
    setDisabledd(!disabledd);
    setIsOpenAuth(!isOpenAuth);
  }

  function toggleModalOtp() {
    setLoading(false);
    setOpenOtp(!openOtp);
  }

  const [time, setTime] = React.useState("");

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [otp, setOtp] = useState("");
  const [myUser, setUser] = useState({});
  const getRandomPassword = (size) => {
    const randomValues = new Uint8Array(size);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
      .map((n) => n.toString(16).padStart(2, "0"))
      .join("");
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    // toggleAuthModal()
    setRemainderTime(30);

    setLoading(true);
    handleResendotp();

    try {
      const signupUser = await Auth.signUp({
        username: phoneNumber,
        password: getRandomPassword(30),
      });

      const signInUser = await Auth.signIn(phoneNumber);

      setUser(signInUser);
      if (signInUser) toggleModalOtp();
    } catch (error) {
      if (error["code"] === "UsernameExistsException") {
        const signInUser = await Auth.signIn(phoneNumber);
        setUser(signInUser);
        if (signInUser) toggleModalOtp();
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const [error, setError] = useState(false);
  const [resend, setResend] = useState(false);
  const [userDisabled, setUserDisabled] = useState(false);

  const handleError = () => {
    setError(!error);
  };

  const handleResend = () => {
    setResend(!resend);
  };

  const handleUserDisabled = () => {
    setUserDisabled(!userDisabled);
  };
  const handleResendOtp = async () => {
    setRemainderTime(30);
    handleResendotp();
    try {
      const signupUser = await Auth.signUp({
        username: phoneNumber,
        password: getRandomPassword(30),
      });
      const signInUser = await Auth.signIn(phoneNumber);
      setOtp("");
      handleResend();
      setUser(signInUser);
    } catch (error) {
      if (error["code"] === "UsernameExistsException") {
        const signInUser = await Auth.signIn(phoneNumber);
        setOtp("");
        handleResend();
        setUser(signInUser);
      } else {
      }
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleOtpAuth = (e) => {
    e.preventDefault();
    setLoading(true);
    if (myUser.challengeName === "CUSTOM_CHALLENGE") {
      // to send the answer of the custom challenge
      Auth.sendCustomChallengeAnswer(myUser, otp)
        .then((myUser) => {
          if (myUser.signInUserSession) {
            setOpenOtp(true);
            login(myUser.signInUserSession.idToken.jwtToken);
            handleCheck();
          } else {
            setLoading(false);
            setOtp("");
            handleError();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.code === "NotAuthorizedException") handleUserDisabled();
        });
    } else {
    }
  };
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div className="row">
      <Snackbar
        style={
          !isMobile ? { width: "350px", margin: "0 5px" } : { width: "auto" }
        }
        open={resend}
        autoHideDuration={6000}
        onClose={handleResend}
      >
        <Alert onClose={handleResend} severity="success" sx={{ width: "100%" }}>
          OTP is resent Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        style={
          !isMobile ? { width: "350px", margin: "0 5px" } : { width: "auto" }
        }
        open={userDisabled}
        autoHideDuration={6000}
        onClose={handleUserDisabled}
      >
        <Alert
          onClose={handleUserDisabled}
          severity="error"
          sx={{ width: "100%" }}
        >
          User is disabled.
        </Alert>
      </Snackbar>
      <Snackbar
        style={
          !isMobile ? { width: "350px", margin: "0 5px" } : { width: "auto" }
        }
        open={error}
        autoHideDuration={6000}
        onClose={handleError}
      >
        <Alert onClose={handleError} severity="error" sx={{ width: "100%" }}>
          Enter the correct OTP!
        </Alert>
      </Snackbar>

      <div className="col-md-12">
        <div className={styles.modal}>
          <Modal
            isOpen={isOpenAuth}
            ariaHideApp={false}
            onRequestClose={toggleAuthModal}
            contentLabel="My dialog"
            className={styles.mymodal}
            overlayClassName={styles.myoverlay}
            closeTimeoutMS={50}
          >
            <form
              onSubmit={(e) => {
                handleSendOtp(e);
              }}
            >
              <div className={styles.authRow}>
                <Typography className={styles.authHeading}>Log In</Typography>

                <button
                  className={styles.closeAuthBtn}
                  onClick={toggleAuthModal}
                  type="reset"
                >
                  <CloseIcon />
                </button>
              </div>
              <Paper variant="outlined" sx={{ p: 2, border: "1px solid grey" }}>
                <PhoneInput
                  data-testid="phoneNo"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e)}
                  autoFocus
                />
              </Paper>

              <button
                className={styles.authOtpBtn}
                // onClick={() => {
                //     handleSendOtp();
                //     setRemainderTime(30);
                // }}
                type="submit"
              >
                {" "}
                {loading && <CircularProgress color="inherit" size={15} />}
                {!loading && <>Send OTP</>}
              </button>
            </form>
          </Modal>

          <Modal
            isOpen={openOtp}
            ariaHideApp={false}
            onRequestClose={toggleModalOtp}
            contentLabel="My dialog"
            className={styles.mymodal}
            overlayClassName={styles.myoverlay}
            closeTimeoutMS={50}
            shouldCloseOnOverlayClick={false}
          >
            <form
              onSubmit={(e) => {
                handleOtpAuth(e);
              }}
            >
              <div className={styles.authRow}>
                <Typography className={styles.authHeading}>
                  Enter OTP for {phoneNumber}
                  {/* <span style={{fontWeight:'lighter'}} > */}
                  {/* </span>  */}
                </Typography>
                <button
                  className={styles.closeAuthBtn}
                  onClick={toggleModalOtp}
                  type="reset"
                >
                  <CloseIcon />
                </button>
              </div>
              <Typography className={styles.authHeading2}>
                OTP Sent Successfully
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                type="text"
                inputProps={{
                  "data-testid": "enterOtp",
                }}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                label="Enter the OTP"
                autoFocus
                fullWidth
              />
              {ResendOtp ? (
                <span className={styles.reaminderTimer}>
                  {" "}
                  Resend Otp in {RemainderTime} seconds{" "}
                </span>
              ) : (
                <button
                  className={styles.resendBtn}
                  onClick={handleResendOtp}
                  type="button"
                >
                  Resend Otp
                </button>
              )}

              <button
                className={styles.authOtpBtn}
                // onClick={handleOtpAuth}
                type="submit"
              >
                {loading && <CircularProgress color="inherit" size={15} />}
                {!loading && <>Verify OTP</>}
              </button>
            </form>
          </Modal>

          {disabledd ? (
            <button
              data-testid="userBtn"
              style={{ cursor: "pointer" }}
              onClick={handleCheck}
            >
              <img
                className={styles.userIcon}
                style={{ cursor: "pointer" }}
                src={userIcon}
                alt="user-profile"
                loading="lazy"
              />
            </button>
          ) : (
            <button onClick={handleCheck} disabled>
              <img
                className={styles.userIcon}
                src={userIcon}
                alt="user-profile"
                loading="lazy"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalComp;
