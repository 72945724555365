import React, { useEffect } from "react";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
} from "@material-ui/core";
import Verify from "../assets/verify.png";
import { useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  display: "flex",
  justifyContent: "space-between",
  height: { xs: "43%", sm: "46%" },
  textAlign: "center",
  boxShadow: 24,
  flexDirection: "column",
  p: 3,
  alignItems: "center",
};

const OrderModal = ({ open, setOpen }) => {
  const isOrderPlaced = useSelector(
    (state) => state.app.Websocket_data.OrderPlaced
  );
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOrderPlaced}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOrderPlaced}>
        <Box
          sx={style}
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "8px",
          }}
        >
          <img
            alt="order success"
            height={"70px"}
            width={"70px"}
            src={Verify}
          ></img>
          <Typography variant="h5">Success</Typography>
          <Typography variant="h6">
            This order has already placed
            <br></br>
            Please refresh the page
          </Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              window.location.href = window.location.pathname;
            }}
          >
            Refresh page
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default OrderModal;
