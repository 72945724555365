import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AuthContext } from "./context/AuthContext";

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext);

  let isTrue = false;
  const subdomain = window.location.host.split(".")[0];
  let arr = [
    "www",
    "insmeal",
    "develop",
    "stage",
    "prod",
    "localhost:3000",
    "localhost:3006",
  ];
  if (!arr.includes(subdomain)) {
    isTrue = true;
  }
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to={!isTrue ? "/" : "/Home"} />
        )
      }
    />
  );
};

export default AuthRoute;
