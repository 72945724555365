import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AuthContext } from "./context/AuthContext";

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext);

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to={`menu`} />
      }
    />
  );
};

export default ProtectedRoutes;
