export const RESET_APP_STATE = "RESET_APP_STATE";
export const SET_MENU_ITEM_DETAILS = "SET_MENU_ITEM_DETAILS";
export const SET_DELIVERY_MODE = "SET_DELIVERY_MODE";
export const SET_PICKUP_MODE = "SET_PICKUP_MODE";
export const SET_RESTAURANT_LANDING_DETAILS = "SET_RESTAURANT_LANDING_DETAILS";
export const SET_LOCATION = "SET_LOCATION";
export const SET_RESTNAME = "SET_RESTNAME";
export const SET_ORDERID = "SET_ORDERID";
export const SET_FILTERS_SELECTED = "SET_FILTERS_SELECTED";
export const SET_SEARCH_RESTAURANT_NAME = "SET_SEARCH_RESTAURANT_NAME";
export const SET_SORTBY = "SET_SORTBY";
export const SEARCHING_RESTAURANTS = "SEARCHING_RESTAURANTS";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const WEBSOCKET_ESTABLISH = "WEBSOCKET_ESTABLISH";

export const WEBSOCKET_INSTANCE = "WEBSOCKET_INSTANCE";
export const WEBSOCKET_MESSAGE = "WEBSOCKET_MESSAGE";
export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_ORDERPLACED = "WEBSOCKET_ORDERPLACED";
export const WEBSOCKET_PAYMENT = "WEBSOCKET_PAYMENT";
import { w3cwebsocket as W3CWebSocket } from "websocket";
let client;
export const resetAppState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APP_STATE });
  };
};

export const setDeliveryMode = () => {
  return (dispatch) => {
    dispatch({ type: SET_DELIVERY_MODE });
  };
};

export const setPickupMode = () => {
  return (dispatch) => {
    dispatch({ type: SET_PICKUP_MODE });
  };
};

export const setLocation = (location, coords = {}) => {
  return (dispatch) => {
    dispatch({ type: SET_LOCATION, payload: { location, coords } });
  };
};

export const setRestname = (rest_name) => {
  return (dispatch) => {
    dispatch({ type: SET_RESTNAME, payload: { rest_name } });
  };
};

export const setOrderId = (orderData = {}) => {
  return (dispatch) => {
    dispatch({ type: SET_ORDERID, payload: { orderData } });
  };
};

export const setSelectedFilters = (selectedFilters = []) => {
  return (dispatch) => {
    dispatch({ type: SET_FILTERS_SELECTED, payload: { selectedFilters } });
  };
};

export const setRestaurantInputQuery = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_RESTAURANT_NAME,
      payload: { queryValue: value },
    });
  };
};

export const setSortby = (sortby = "") => {
  return (dispatch) => {
    dispatch({ type: SET_SORTBY, payload: { sortby } });
  };
};
export const getRestaurantlandingDetails = (slug) => {
  return (dispatch, getState) => {
    if (slug) {
      const url = `${process.env.REACT_APP_API_URL}restaurant/slug/${slug}`;
      const state = getState().app;
      fetch(url, {
        method: "GET",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          dispatch({
            type: SET_RESTAURANT_LANDING_DETAILS,
            payload: { data: data },
          });
        })
        .catch((error) => {});
    }
  };
};

export const getMenuItemDetails = (id) => {
  return (dispatch, getState) => {
    const url = id
      ? `${process.env.REACT_APP_API_URL}restaurant/${id}/menu?restId=${id}`
      : `${process.env.REACT_APP_API_URL}restaurant/test-id/menu`;
    const state = getState().app; //here is problem
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          dispatch({
            type: SET_MENU_ITEM_DETAILS,
            payload: { data: data },
          });
        } else {
          dispatch({
            type: SET_MENU_ITEM_DETAILS,
            payload: { data: [] },
          });
        }
      })
      .catch((error) => {});
  };
};

export const getRestaurantsList = () => {
  console.log("we are calling this");
  return (dispatch, getState) => {
    const url = `${process.env.REACT_APP_API_URL}query`;
    const state = getState().app;

    let body = {
      location: state.locationCoords,
      rest_name: state.rest_name,
      filters: state.selectedFilters,
      query: state.restaurantInputQuery,
      sortby: state.sortby,
      type: state.deliveryMode ? "delivery" : "pickup",
    };
    // exit early if not valid geopoints
    if (isNaN(body.location?.lat) || isNaN(body.location?.lon)) {
      return;
    }

    dispatch({ type: SEARCHING_RESTAURANTS });

    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          dispatch({ type: SEARCH_ERROR, payload: data.error });
          return;
        } else {
          dispatch({
            type: SEARCH_RESULTS,
            payload: {
              searchResults: data.searchresults,
              filters: data.filter,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: SEARCH_ERROR, payload: { error } });
      });
  };
};
export const establish_websocket = (cartid) => {
  console.log(process.env);
  return (dispatch, getState) => {
    client = new W3CWebSocket(
      process.env.REACT_APP_CUSTOMER_WEBSOCKET + `?cartid=${cartid}`
    );

    client.onopen = (response) => {
      dispatch({
        type: WEBSOCKET_CONNECT,
        payload: { connected: true, cartid: cartid },
      });
      client.send(
        JSON.stringify({
          action: "updateorder",
          message: { CheckPaymentStatus: true },
        })
      );

      console.log("websocket connect successfully");
    };
    client.onmessage = (e) => {
      console.log("websocket recived info", e);
      const receive_data = JSON.parse(e.data);
      if (
        receive_data?.isPaymentProceeding ||
        receive_data?.isPaymentProceeding === false
      ) {
        console.log("insidepayment");
        dispatch({
          type: WEBSOCKET_PAYMENT,
          payload: receive_data?.isPaymentProceeding,
        });
      } else if (
        receive_data?.OrderPlaced ||
        receive_data?.OrderPlaced === false
      ) {
        console.log("insideorder");
        dispatch({
          type: WEBSOCKET_ORDERPLACED,
          payload: receive_data?.OrderPlaced,
        });
        dispatch({ type: WEBSOCKET_PAYMENT, payload: false });
      } else if (receive_data?.data) {
        dispatch({ type: WEBSOCKET_MESSAGE, payload: receive_data });
      }
    };

    client.onclose = () => {
      const state = getState().app;
      console.log(state, "dkdjsk");
      establish_websocket(state.Websocket_data.connected.cartid);
    };
    client.onerror = (error) => {
      console.log("error while websocket connecting", error);
      dispatch({
        type: WEBSOCKET_CONNECT,
        payload: { connected: false, cartid: undefined },
      });
    };
  };
};
export const Websocket_Send = (send) => {
  console.log("send the sebsocket data", send);
  try {
    client &&
      client.send(
        JSON.stringify({
          action: "updateorder",
          message: send,
        })
      );
    console.log("websocket send info", send);
  } catch (error) {
    console.log("websocket send error", error);
  }
};

export const Websocket_Closed = () => {
  return (dispatch, getState) => {
    const state = getState().app;
    dispatch({
      type: WEBSOCKET_CONNECT,
      payload: { connected: false, cartid: undefined },
    });
  };
};
