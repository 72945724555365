import React, { useReducer, createContext } from "react";

const initialState = {
  theme: null,
};

const initialStatus = {
  CartItem: false,
};

const initialSocketState = {
  SocketData: null,
};

const ThemeContext = createContext({
  theme: null,
  setTheme: (themeData) => {},
});

const SocketContext = createContext({
  SocketData: null,
  setSocketData: (data) => {},
});

const CartContext = createContext({
  CartItem: false,
  setCartItem: (itemData) => {},
});

function themeReducer(state, action) {
  switch (action.type) {
    case "SET_THEME": {
      return {
        ...state,
        theme: action.payload,
      };
    }
    default:
      return state;
  }
}

function socketReducer(state, action) {
  switch (action.type) {
    case "SET_CONTEXT": {
      return {
        ...state,
        SocketData: action.payload,
      };
    }
    default:
      return state;
  }
}

function cartReducer(state, action) {
  switch (action.type) {
    case "SET_CART": {
      return {
        ...state,
        CartItem: action.payload,
      };
    }
    default:
      return state;
  }
}

function ThemeProvider(props) {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  const setTheme = (themeData) => {
    // localStorage.setItem("theme", JSON.stringify(themeData))
    dispatch({
      type: "SET_THEME",
      payload: themeData,
    });
  };

  return (
    <ThemeContext.Provider
      value={{ theme: state.theme, setTheme }}
      {...props}
    />
  );
}

function CartProvider(props) {
  const [state, dispatch] = useReducer(cartReducer, initialStatus);

  const setCartItem = (itemData) => {
    dispatch({
      type: "SET_CART",
      payload: itemData,
    });
  };

  return (
    <CartContext.Provider
      value={{ CartItem: state.CartItem, setCartItem }}
      {...props}
    />
  );
}

function SocketProvider(props) {
  const [state, dispatch] = useReducer(socketReducer, initialSocketState);

  const setSocketData = (itemData) => {
    dispatch({
      type: "SET_SOCKET",
      payload: itemData,
    });
  };

  return (
    <SocketContext.Provider
      value={{ SocketData: state.SocketData, setSocketData }}
      {...props}
    />
  );
}

export {
  ThemeContext,
  ThemeProvider,
  CartContext,
  CartProvider,
  SocketContext,
  SocketProvider,
};
