import {
  RESET_APP_STATE,
  SET_DELIVERY_MODE,
  SET_PICKUP_MODE,
  SET_RESTAURANT_LANDING_DETAILS,
  SET_LOCATION,
  SET_RESTNAME,
  WEBSOCKET_PAYMENT,
  SET_FILTERS_SELECTED,
  SET_SEARCH_RESTAURANT_NAME,
  SET_SORTBY,
  SET_MENU_ITEM_DETAILS,
  SEARCHING_RESTAURANTS,
  SEARCH_RESULTS,
  SEARCH_ERROR,
  SET_ORDERID,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_CONNECT,
  WEBSOCKET_ORDERPLACED,
} from "../actions/appActions";

const initState = {
  location: "",
  rest_name: "",
  locationCoords: { lat: null, lng: null },
  deliveryMode: true,
  selectedFilters: {
    cuisines: [],
  },
  restaurantInputQuery: "",
  sortby: "",
  searching: false,
  Websocket_data: {
    connected: { connected: false, cartid: undefined },
    OrderPlaced: false,
    Received_Data: null,
    isPaymentProceeding: false,
  },
  // api result
  filters: {},
  orderData: {},
  restaurantLanding: {},
  menu: { menuitems: [0] },
  searchResults: {},
};

const appReducer = (state = initState, action) => {
  let { type, payload } = action;

  switch (type) {
    case RESET_APP_STATE:
      return {
        ...initState,
      };

    case SET_DELIVERY_MODE:
      return {
        ...state,
        deliveryMode: true,
      };

    case SET_PICKUP_MODE:
      return {
        ...state,
        deliveryMode: false,
      };

    case SET_LOCATION:
      return {
        ...state,
        location: payload.location,
        locationCoords: payload.coords,
      };
    case SET_RESTNAME:
      return {
        ...state,
        rest_name: payload.rest_name,
      };
    case SET_ORDERID:
      return {
        ...state,
        orderData: payload.orderData,
      };

    case SET_FILTERS_SELECTED:
      return {
        ...state,
        selectedFilters: {
          cuisines: payload.selectedFilters,
        },
      };

    case SET_SEARCH_RESTAURANT_NAME:
      return {
        ...state,
        restaurantInputQuery: payload.queryValue,
      };

    case SET_SORTBY:
      return {
        ...state,
        sortby: payload.sortby,
      };

    case SEARCHING_RESTAURANTS:
      return {
        ...state,
        searching: true,
      };

    case SEARCH_RESULTS:
      return {
        ...state,
        filters: payload.filters,
        searchResults: payload.searchResults,
        searching: false,
      };

    case SEARCH_ERROR:
      return {
        ...state,
        searching: false,
      };

    case SET_RESTAURANT_LANDING_DETAILS:
      return {
        ...state,
        restaurantLanding: payload.data,
      };
    case SET_MENU_ITEM_DETAILS:
      return {
        ...state,
        menu: { menuitems: payload.data },
      };
    case WEBSOCKET_CONNECT:
      return {
        ...state,
        Websocket_data: { ...state.Websocket_data, connected: payload },
      };
    case WEBSOCKET_ORDERPLACED:
      return {
        ...state,

        Websocket_data: { ...state.Websocket_data, OrderPlaced: payload },
      };
    case WEBSOCKET_PAYMENT:
      return {
        ...state,
        Websocket_data: {
          ...state.Websocket_data,
          isPaymentProceeding: payload,
        },
      };
    case WEBSOCKET_MESSAGE:
      return {
        ...state,
        Websocket_data: { ...state.Websocket_data, Received_Data: payload },
      };
    default:
      return state;
  }
};

export default appReducer;
