import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loading = (props) => {
  return (
    <div
      data-testid="loader"
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "200px",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
