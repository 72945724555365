import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import logo from "../assets/logo.png";
import Modal from "./ModalComp";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

function Header(props) {
  const history = useHistory();

  const [user, notUser] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  let isTrue = false;
  const subdomain = window.location.host.split(".")[0];
  let arr = ["www", "insmeal", "develop", "stage", "prod", "localhost:3000"];
  if (!arr.includes(subdomain)) {
    isTrue = true;
  }
  const returnToHomepage = () => {
    if (isTrue) {
      history.push("/Home");
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    AunthicatedUser();
  }, []);

  const AunthicatedUser = () => {
    Auth.currentUserInfo()
      .then((user) => {
        notUser(user);
      })
      .catch((err) => console.log("no current user"));
  };

  const handleToggle = () => {
    if (user) {
      setOpen((prevOpen) => !prevOpen);
    } else {
      setOpen(false);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseLogout = () => {
    Auth.signOut();
    history.push(`/`);
    localStorage.clear();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={styles.header}>
      <img
        className={styles.logo}
        src={logo}
        alt="logo"
        loading="lazy"
        onClick={returnToHomepage}
      />

      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ backgroundColor: "transparent" }}
        >
          <Modal />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      style={{
                        border: "1px solid #F0F0F2",
                      }}
                      onClick={handleCloseLogout}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default React.memo(Header);
