import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import store from "./store";
import AppRoutes from "./AppRoutes";
import { ThemeProvider as DefaultTheme, CartProvider } from "./context/context";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider as MaterialStyleTheme } from "@material-ui/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { RestaurantProvider } from "./context/restaurant";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
});

import "./styles.css";
import Amplify from "@aws-amplify/core";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

Amplify.configure({
  Auth: {
    authenticationFlowType: "CUSTOM_AUTH",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT,
  },
  Analytics: {
    disabled: true,
  },
});
ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MaterialStyleTheme theme={theme}>
          <DefaultTheme>
            <AuthProvider>
              <CartProvider>
                <RestaurantProvider>
                  <AppRoutes />
                </RestaurantProvider>
              </CartProvider>
            </AuthProvider>
          </DefaultTheme>
        </MaterialStyleTheme>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
