import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button, Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mt100: {
    marginTop: 100,
  },
  mt20: {
    marginTop: 20,
  },
  errorColor: {
    color: "#de5c5c",
  },
  bStyle: {
    backgroundColor: "#fba904",
    color: "white",
    fontSize: "19px",
    "&:hover": {
      backgroundColor: "#fba904",
      color: "white",
    },
  },
  dflex: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0000001a",
    marginTop: "-30px",
  },
}));
const Fallback = ({ error }) => {
  const classes = useStyles();

  const handleReload = () => {
    window.location.reload(false);
  };
  return (
    <Box className={classes.dflex}>
      <Grid container direction="row" padding="0px 10px">
        <Grid item xs={false} sm={3} />
        <Grid item xs={12} sm={6}>
          <Typography variant="h1" color="primary" align="center">
            Insmeal
          </Typography>
        </Grid>
        <Grid item xs={false} sm={3} />
        <Grid item xs={false} sm={3} />
        <Grid item xs={12} sm={6} className={classes.mt20}>
          <Typography
            variant="h5"
            className={classes.errorColor}
            align="center"
          >
            {error
              ? /Loading chunk [\d]+ failed/g.test(error)
                ? "Internet connection failed Please Reload page"
                : error
              : "App broke down😥😥!!! Please reload!"}
          </Typography>
        </Grid>
        <Grid item xs={false} sm={3} />
        <Grid item xs={false} sm={3} />
        <Grid item xs={12} sm={6} className={classes.mt20}>
          <Button
            data-testid="fallBackBtn"
            className={classes.bStyle}
            fullWidth
            variant="contained"
            size="large"
            onClick={handleReload}
          >
            Reload
          </Button>
        </Grid>
        <Grid item xs={false} sm={3} />
      </Grid>
    </Box>
  );
};

export default Fallback;
